<template>
    <div>
        <b-row>
            <b-col md="12" lg="3">
                <b-overlay :show="!dataLoaded">
                    <b-card>
                        <b-form-group>
                            <label>Name:</label>
                            <b-form-input
                                :disabled="!editEnabled"
                                type="text"
                                placeholder="Name"
                                v-model="editObject.name"
                            />
                        </b-form-group>

                        <hr/>

                        <b-button v-if="!editEnabled" variant="warning" @click="editEnabled = true" class="mr-1">
                            <feather-icon
                                icon="EditIcon"
                                class="mr-50"
                            />
                            <span class="align-middle">Edit</span>
                        </b-button>

                        <b-button v-if="editEnabled" variant="success" @click="saveSignage" class="mr-1">
                            <feather-icon
                                icon="SaveIcon"
                                class="mr-50"
                            />
                            <span class="align-middle">Save</span>
                        </b-button>

                        <b-button v-if="editEnabled" variant="danger" @click="editEnabled = false">
                            <feather-icon
                                icon="XIcon"
                                class="mr-50"
                            />
                            <span class="align-middle">Cancel</span>
                        </b-button>

                        <b-button v-if="editEnabled" variant="danger" @click="removeSignage" class="float-right">
                            <feather-icon
                                icon="Trash2Icon"
                                class="mr-50"
                            />
                            <span class="align-middle">Remove</span>
                        </b-button>
                    </b-card>
                </b-overlay>
            </b-col>
            <b-col md="12" lg="9">
                <DigitalSignageElements/>
            </b-col>
        </b-row>

    </div>
</template>
<script>
    import {BButton, BCard, BFormGroup, BFormInput, BOverlay, BRow, BCol} from 'bootstrap-vue'
    import DigitalSignageElements from '@/views/DigitalSignage/Elements/DigitalSignageElements'

    export default {
        components: {
            DigitalSignageElements,
            BOverlay,
            BCard,
            BButton,
            BFormInput,
            BFormGroup,
            BRow,
            BCol
        },
        data() {
            return {

                signage: {},
                signageElements: [],
                dataLoaded: false,

                editEnabled: false,
                editObject: {}
            }
        },
        methods: {
            loadData() {
                this.dataLoaded = false
                const thisIns = this
                const loadPromise = this.$http.get(`/api/management/v1/digital_signage/${  this.$route.params.id}`)
                loadPromise.then(function(response) {
                    thisIns.signage = response.data
                    thisIns.editObject = JSON.parse(JSON.stringify(response.data))
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                }).finally(function() {
                    thisIns.dataLoaded = true
                })
            },
            saveSignage() {
                this.dataLoaded = false
                const thisIns = this
                const addPromise = this.$http.put(`/api/management/v1/digital_signage/${  this.$route.params.id}`, this.editObject)
                addPromise.then(function() {
                    thisIns.editEnabled = false
                    thisIns.loadData()
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            },
            removeSignage() {
                this.dataLoaded = false
                const thisIns = this
                const deletePromise = this.$http.delete(`/api/management/v1/digital_signage/${  this.$route.params.id}`)
                deletePromise.then(function() {
                    thisIns.loadData()
                }).catch(function(error) {
                    thisIns.$printError(error.response.data)
                })
            }
        },
        mounted() {
            this.loadData()
        }
    }
</script>